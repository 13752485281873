<template>
    <b-card-code title="جدول الصلاحيات" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- filter -->
          <b-form-group
            label="فلتر"
            label-cols-sm="1"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="md">
              <b-form-input
                id="filterInput"
                v-model="title"
                type="search"
                placeholder="ابحث عن .."
              />
              <b-input-group-append>
                <b-button @click="resetSearch()">
                  الغاء الفلتر
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-modal.model-a-w
                >
                  <feather-icon icon="FilePlusIcon" class="mr-50" />
                  <span class="align-middle">اضافة صلاحية جديدة</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
  <div class="demo-vertical-spacing">
        <b-progress
        v-if="precentage !== 0"
          :value="precentage"
          max="100"
        />
        </div>
      <b-table
        ref="table"
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :items="items"
        :fields="fields"
      >
        <template #cell(times)="res">
          <b-badge
          v-for="time in res.item.times"
          :key="time.id"
            pill
            variant="primary"
          >
            <p style="font-size: 20px; align-items: center;justify-content: center;">{{ tde(time.time ) }}</p>
          </b-badge> 
        </template>
      </b-table>
  
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            @change="check()"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
  
        <!-- pagination -->
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @change="check()"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>
      <b-modal
        id="model-e-w"
        ok-title="حفظ التعديلات"
        centered
        size="lg"
        title="تعديل الصلاحية"
        @show="checkModel"
        @ok="uM"
        @hidden="rEF"
      >
        <b-form novalidate class="needs-validation" style="margin-bottom:280px;">
          <b-form-row>
            <b-col md="12">
              <label for="input-valid1">العنوان:</label>
              <b-form-input
                id="input-valid1"
                v-model="editform.name"
                :state="editform.name.length > 0"
                placeholder="العنوان"
              />
            </b-col>
            <b-col md="12">
              <label for="input-valid1">الصفحات:</label>
            <v-select
              v-model="editform.permissions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :options="pages"
              label="اختر الصفحات"
            >
              <template #option="{ title, icon }">
                <feather-icon
                  :icon="icon"
                  size="16"
                  class="align-middle mr-25"
                />
                <span> {{ title }}</span>
              </template>
            </v-select>
            </b-col>
          </b-form-row>
        </b-form>
      </b-modal>
      <b-modal
        id="model-a-w"
        ok-title="حفظ البيانات"
        centered
        size="lg"
        title="اضافة صلاحية جديدة"
        @show="checkModel"
        @ok="aNM"
        @hidden="rF"
      >
        <b-form novalidate class="needs-validation" style="margin-bottom:280px;" >
          <b-form-row>
            <b-col md="12">
              <label for="input-valid1">العنوان:</label>
              <b-form-input
                id="input-valid1"
                v-model="form.name"
                :state="form.name.length > 0"
                placeholder="العنوان"
              />
            </b-col>
            <b-col md="12">
              <label for="input-valid1">الصفحات:</label>
            <v-select
              v-model="form.permissions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :options="pages"
              label="id"
            >
              <template #option="{ title, icon }">
                <feather-icon
                  :icon="icon"
                  size="16"
                  class="align-middle mr-25"
                />
                <span> {{ title }}</span>
              </template>
            </v-select>
            </b-col>
          </b-form-row>
        </b-form>
      </b-modal>
  
      <b-modal
        id="modal-center"
        centered
        title="حذف صلاحية"
        @ok="dM"
        ok-title="خذف الحقل"
      >
        <b-card-text>
          هل تريد حذف هذه الحقل  " {{ this.editform.name }} "
          ?
        </b-card-text>
      </b-modal>
    </b-card-code>
  </template>
  
  <script>
  import vSelect from "vue-select";
  import store from "@/store";
  import { togglePasswordVisibility } from "@core/mixins/ui/forms";
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  import {
    BTable,
    BAvatar,
    BModal,
    BDropdown,
    BDropdownItem,
    BFormCheckboxGroup,
    BForm,
    BFormRow,
    BCol,
    VBModal,
    BBadge,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BFormGroup,
    BFormCheckbox,
    BCardText,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,BProgress,
    BButton,
    BCardBody,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  
  export default {
    components: {
      BCardCode,
      BTable,
      BForm,
      vSelect,BProgress,
      BAvatar,
      BBadge,
      BFormRow,
      BDropdown,
      BDropdownItem,
      BFormGroup,
      togglePasswordVisibility,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormCheckboxGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BCardBody,
      BModal,
      VBModal,
      Ripple,
      BCol,
      BFormCheckbox,
      BCardText,
      BFormValidFeedback,
      BFormInvalidFeedback,
    },
    data() {
      return {
        precentage:0,
        teacher_id: "",
        perPage: 3,
        pageOptions: [3, 5, 10, 100],
        totalRows: 1,
        currentPage: 0,
        role:"",
        title:"",
        filter: null,
        copres:0,
        filterOn: [],
        roles: [],
        teachers: [],
        stages: [],
        fields: [
          {
            key: "date",
            label: "التاريخ",
          },
          { key: "times", label: "الاوقات" },
        ],
        items: [],
        form: {
          name: "",
          permissions:[]
        },
        editform: {
          name: "",
          permissions:[],
          id: null,
        },
        selected: [],
        selected2: [],
        pages:[],
      };
    },
    watch: {
      role: function (val) {
        this.gS();
      },
      title: function (val) {
        this.gS();
      },
      
    "$route.params.id": {
      handler: function (search) {
        this.gS();
      },
    },
      "editform.department_id": function (val) {
        if (val != null || val!="" ) {
          this.gSM1()
        }
      },
      "form.department_id": function (val) {
        if (val != null || val!="" ) {
          this.gSM3()
        }
      },
    },
    mixins: [togglePasswordVisibility],
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
      },
    },
    directives: {
      Ripple,
    },
    mounted() {
      this.ID(), this.gS(), this.gSM()
    },
    methods: {
      async gSM() {
        await this.axios
          .get(`pages?take=100&skip=0`)
          .then((res) => {
            this.pages = [];
            this.pages = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      async gSM1() {
        this.stages = []
        this.editform.stage_id==""
        console.log(this.form.department_id)
        if(! Number.isInteger(this.editform.department_id)){
          this.editform.department_id = this.editform.department_id["id"]
  
        }
        await this.axios
          .get(`stage/clint/department/${this.editform.department_id}?select=true`)
          .then((res) => {
            this.stages = [];
            this.stages = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      async gSM3() {
        this.stages = []
        this.form.stage_id==""
        if(! Number.isInteger(this.form.department_id)){
          this.form.department_id = this.form.department_id["id"]
  
        }
        await this.axios
          .get(`stage/clint/department/${this.form.department_id}?select=true`)
          .then((res) => {
            this.stages = [];
            this.stages = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      async gSM2() {
        this.teachers = []
        this.editform.teacher_id==""
        await this.axios
          .get(`teachers-name`)
          .then((res) => {
            this.teachers = [];
            this.teachers = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      checkModel() {
        this.selected = [];
        if (this.editform.is_sustainabilty == 1) {
          this.selected.push("is_sustainabilty");
        }
        if (this.editform.is_energy == 1) {
          this.selected.push("is_energy");
        }
      },
      resetCheckboxes() {
        this.selected = [];
      },
      async uM() {
        await this.axios
          .post(`rules/${this.editform.id}`, this.editform,{
            onUploadProgress: progressEvent => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            }
          })
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "update Success",
                "Your update is done successfully"
              );
            }
            this.precentage=0
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      async aNM() {
        await this.axios
          .post(`rules`, this.form,{
            onUploadProgress: progressEvent => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            }
          })
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "update Success",
                "Your model is added successfully"
              );
            }
            this.rF();
            this.precentage=0
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
        this.rF();
      },
      rF() {
        this.form.name = ""
        this.form.permissions = ""
      },
      rEF() {
        this.editform.name = "",
        this.editform.permissions = [],
        this.editform.id = ""
      },
      async dM() {
        await this.axios
          .delete(`rules/${this.editform.id}`)
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "Delete Success",
                "Your item is deleted successfully"
              );
            }
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      errorToast() {
        this.makeToast(
          "danger",
          "update failed",
          "some thing want wrong, i`m sorry :("
        );
      },
      eRM(item) {
        console.log(item)
        this.editform.name = item.name;
        this.editform.permissions = item.pages;
        this.editform.id = item.id;
      },
      dRM(item) {
        this.editform.name = item.name;
        this.editform.id = item.id;
      },
      check() {
        setTimeout(() => this.gS(), 1000);
      },
      ID() {
        this.teacher_id = localStorage.getItem("NisourTeacherId");
      },
      async gS() {
        // ?begin=2023-03-03&end=2023-03-29
        await this.axios
          .get(`presence/${this.$route.params.id}`)
          .then((res) => {
            this.items = [];
            this.totalRows = res.data.totalCount;
            this.items = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      makeToast(variant = null, title = null, body = null) {
        this.$bvToast.toast(body, {
          title: title,
          variant,
          solid: false,
        });
      },
      resetSearch(){
        this.title = '',
        this.department = ''
        this.gS()
      },
      tde(d){
        return d.split(' ')[1];
      }
    },
  };
  </script>
   